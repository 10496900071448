<template>
  <div class="news">
    <headbar :title="$t('新闻公告')"></headbar>

    <div class="news-item" v-for="item in list" :key="item.id" @click="tolink('NewsDetails?id=' + item.id)">
      <div class="news-title">{{ item.newstitle }}</div>
      <div class="news-date">{{ item.newstime }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {},
  setup() {
    return {
      list: ref([])
    }
  },
  created() {
    this.getData()
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    getData() {
      let _this = this
      _this.$request.post(
        "api/News/List",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          _this.list = data
        }
      )
    }
  }
}
</script>

<style scoped>
.news {
  padding: 10px 10px 0;
}

.news-item {
  margin-top: 10px;
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  background-color: #121C2E;
}

.news-title {
  font-size: 16px;
}

.news-date {
  margin-top: 4px;
}
</style>